@import "mixins";

.app[dir="rtl"]{    

    .options-icon{
        left: -4px;
    }
    .back-to-top{
        right: auto;
        left: 20px;
    }
    .user-menu .user-info img{
        margin-right: 0;
        margin-left: 16px;
    }
    .flag-name{
        margin-left: 0;
        margin-right: 4px;
    }
    .mat-icon.caret{
        direction: rtl;
    }
    .header-image-wrapper .bg{
        right: auto;
    }

    .swiper-container{
        direction: ltr;
    }    
    .swiper-slide{
        direction: rtl;
    }

    .sidenav button.close{
        margin-left: 0;
        margin-right: 178px;
    }


    .all-properties{
        .search-sidenav{
            float: right;
        }
        .mat-drawer-content{
            &.distance{
                padding-left: 0;
                padding-right: 16px;
            }
        }    
    }

    .property-item{
        .address, .date{
            .mat-icon{
                margin-left: 0 !important;
                margin-right: -4px;
            }
        }
        .features p {
            span{
                &:first-child {
                    float: right !important;
                    padding: 0 0 0 .4em !important;	
                }
                + span {
                    float: left !important;
                    padding: 0 .4em 0 0 !important;   	
                }
            } 
        }
        .control-icons{ 
            right: auto !important;
            left: 2px;            
        }
    }

    .compare-item .features p span{
        margin: 0 0 0 8px !important;
    }

    .single-property{ 
        .mat-drawer{  
            .mat-drawer-inner-container{ 
                button.close{  
                    right: auto;
                    left: 2px;
                }
            } 
        } 
        .mat-drawer-content{ 
            &.distance{
                padding-right: 2px;
                padding-left: 16px;
            }  
        }
    }


    .menu-expand-icon{
        right: auto;
        left: 10px;
    }
    .sub-menu{
        @include menu-level-padding('rtl');
        .mat-mdc-button{
            padding-right: 36px;        
        }
    }

    .get-in-touch{ 
        .content{
            padding-left: 0 !important;
            padding-right: 200px;
        }
    }

    footer .content .custom-form .subscribe-btn{
        margin-left: 0;
        margin-right: -4px; 
        border-radius: 4px 0 0 4px;
    }


    .lock-screen{ 
        form{ 
            .name{ 
                right: auto;
                left: 0; 
            } 
            input{  
                padding: 16px 16px 16px 44px; 
                margin-left: 0;
                margin-right: -6px; 
                border-top-right-radius: 0px;
                border-bottom-right-radius: 0px;
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px; 
                box-shadow: -3px -2px 4px -2px rgba(0, 0, 0, 0.2), 
                            -3px 2px 3px 1px rgba(0, 0, 0, 0.14), 
                            -3px 1px 2px -10px rgba(0, 0, 0, 0.12);
            }
            a{ 
                left: -8px;
                right: auto;; 
            }        
            button.submit{
                margin-right: -44px;
                margin-left: 0;
            }
        }  
    } 

    .account{ 
        .mat-drawer{  
            .mat-drawer-inner-container{ 
                button.close{  
                    right: auto;
                    left: 2px;
                }
            } 
        } 
        .mat-drawer-content{ 
            &.distance{
                padding-left: 2px;
                padding-right: 16px;
            }  
        }
    }

    .theme-dialog{  
        .header{   
            padding: 8px 24px 8px 8px;
        } 
    }
}