/* You can add global styles to this file, and also import other style files */
body,p,a,li,ul,h1,h2,h3,h4,h5,h6{
  // y
  
  // font-family: 'Montserrat'!important;
  font-family: 'Tajawal'!important; 
  font-size: 18px;
  }
  h1{font-weight: bold!important;}
  h1 a{font-weight: bold;color:#000;text-decoration: none!important;}
  .adress{font-size:18px;font-weight:bold;color:#59a4da;padding:3px 3px}

  /* sochial media */
.btn-color{color:#DA972E;font-weight:bold;font-size:18px;}


.sochial .fa-angle-right{background-color: #fff;color:#000;padding: 5px 10px;border-radius: 5px 5px;}
.sochial .fa-angle-left{background-color: #fff;color:#000;padding: 5px 10px;border-radius: 5px 5px;}

/* sochial media end */
/* social */

a.social_bt {
  border-radius: 3px;
  color: #fff;
  min-width: 200px;
  display: block;
  padding: 12px;
  line-height: 1;
  position: relative;
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

a.social_bt.facebook {
  background-color: #3B5998;
}

a.social_bt.google {
  background-color: #DC4E41;
}

a.social_bt.linkedin {
  background-color: #0077B5;
}

.add_bottom_15,
a.social_bt {
  margin-bottom: 15px;
}

a.social_bt {
  text-align: center;
  text-decoration: none;
}

a.social_bt.facebook:before {
  content: "\f09a";
}

a.social_bt.google:before {
  content: "\f0d5";
  top: 12px;
}

a.social_bt.linkedin:before {
  content: "\f0e1";
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
  font-family: FontAwesome;
  position: absolute;
  left: 12px;
  top: 10px;
  font-size: 1.3rem;
  color: #fff;
}
.inner-pages .agent-content .netsocials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}

.inner-pages .agent-content .netsocials .fa {
  font-size: 1rem;
  text-align: center;
  background: transparent;
  border: 1px solid #fff;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  margin-right: 1.5rem;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}

.inner-pages .agent-content .netsocials a .fa-facebook {
  background: #3b5998;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-facebook:hover {
  background: #3b5998;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-twitter {
  background: #1da1f2;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-twitter:hover {
  background: #1da1f2;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-google-plus {
  background: #dd4b39;
  color: #fff;
}

.agent-content .netsocials a .fa-google-plus:hover {
  background: #dd4b39;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-youtube {
  background: #cd201f;
  color: #fff;
}

.inner-pages .agent-content .netsocials a .fa-youtube:hover {
  background: #cd201f;
  color: #fff;
}
/* social end */


.pages-footer-2 {
  position: fixed;
  left: 0;
  bottom: 0;
 
 
  color: white;
  text-align: left;
  z-index: 999;
  
}
@media only screen and (max-width: 800px) {
  .header-lap1 {display: none;visibility: hidden;}
}
@media only screen and (min-width: 799px) {
  .header-mob1 {display: none;visibility: hidden;}
}
@media only screen and (max-width: 200px) {
  .header-mob1 {display: none;visibility: hidden;}
}

// @import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500&display=swap');

@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
// @import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Arabic:wght@300&family=Noto+Sans+Arabic:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tajawal&display=swap');
@import "app/theme/styles/spinner";
@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import '~swiper/swiper-bundle.min.css';
@import '~snazzy-info-window/dist/snazzy-info-window.css'; 

@import "app/theme/styles/base";
@import "app/theme/styles/spaces";
@import "app/theme/styles/theme";
@import "app/theme/styles/rtl";


